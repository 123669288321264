import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import Header from "../components/Header";
import { useSearchParams } from 'react-router-dom';
import Profile from '../assets/images/profile.png';
import Ads from "../components/ads";
import axios from 'axios';
import { Player2ImageURL, PlayerImageExtension } from '../components/global';
import { Helmet } from 'react-helmet';

function PlayerInfo() {
    const [searchParams] = useSearchParams();
    const DYPlayerID = searchParams.get('PlayerID');

    const [playerProfile, setPlayerData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPlayerProfile = async () => {
            const apiUrl = 'https://apiv2.cricket.com/cricket/';
            const query = {
                query: `
                  query {
                    getPlayersProfileV2(playerID: "${DYPlayerID}") {
                      name
                      role
                      birthPlace
                      dob
                      battingStyle
                      bowlingStyle
                      fullName
                      description
                      battingStats {
                        format
                        runs
                        ballsFaced
                        fifties
                        foursix
                        hundredsfifties
                        innings
                        strikeRate
                      }
                      bowlingStats {
                        overs
                        wickets
                        average
                        economyRate
                        bestBowling
                        format
                        matches
                      }
                    }
                  }
                `
            };
            setLoading(true);

            try {
                const response = await axios.post(apiUrl, query, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const data = response.data.data.getPlayersProfileV2;
                setPlayerData(data || null); // Set to null if no data
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
        fetchPlayerProfile();
    }, [DYPlayerID]);

    const handleImageError = (e) => {
        e.target.src = Profile; // Fallback image
    };

    const calculateAge = (dob) => {
        if (!dob) return 0; // Return 0 if dob is null or undefined

        const birthDateObj = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDiff = today.getMonth() - birthDateObj.getMonth();
        
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
            age--;
        }
        
        return age;
    };

    // Usage
    const age = playerProfile?.dob ? calculateAge(playerProfile.dob) : '-';

    const plainText = playerProfile ? new DOMParser().parseFromString(playerProfile.description, 'text/html').body.textContent || '' : '';

    return (
        <>

            <Helmet>
                <title>Cricball - Player Information</title>
                <meta name="description" content="Get Live Cricket Scores, Ball by Ball Commentary, Scorecard Updates, Match Facts &amp; related News of all the International &amp; Domestic Cricket Matches across the globe." />
                <meta name="keywords" content="Cricball, 1 Ball Ago, Live Score, Live Cricket Matches" />
            </Helmet>

            <Header />
            <Container>
                <Row className="mt-3">
                    <Col className="d-flex align-items-center justify-content-end">
                        <div className="player-info" style={{ marginRight: '20px', textAlign: 'end' }}>
                            {loading ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>Error: {error}</p>
                            ) : playerProfile === null ? (
                                <p>No data available.</p>
                            ) : (
                                <>
                                    <h4>{playerProfile.name || 'Name'}</h4>
                                    <span>{playerProfile.birthPlace || 'Country'}</span>
                                </>
                            )}
                        </div>
                        <div className="playerProfile">
                            <img
                                src={`${Player2ImageURL}${DYPlayerID}${PlayerImageExtension}`}
                                width={125}
                                height={125}
                                alt={playerProfile?.name || 'Profile'}
                                style={{ borderRadius: '100px' }}
                                onError={handleImageError}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Col md={8}>
                        {playerProfile ? (
                            <>
                                <h5 className="mb-3">Batting Career Summary</h5>
                                <Card className="mb-4">
                                    <Card.Body>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Format</th>
                                                    <th>Innings</th>
                                                    <th>Runs</th>
                                                    <th>BF</th>
                                                    <th>4/6</th>
                                                    <th>100/50</th>
                                                    <th>SR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {playerProfile.battingStats && playerProfile.battingStats.length > 0 ? (
                                                    playerProfile.battingStats.map((stat, index) => (
                                                        <tr key={index}>
                                                            <td>{stat.format}</td>
                                                            <td>{stat.innings || '-'}</td>
                                                            <td>{stat.runs || '-'}</td>
                                                            <td>{stat.ballsFaced || '-'}</td>
                                                            <td>{stat.foursix || '-'}</td>
                                                            <td>{stat.hundredsfifties || '-'}</td>
                                                            <td>{stat.strikeRate || '-'}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="7">No batting stats available.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>

                                <h5 className="mb-3">Bowling Career Summary</h5>
                                <Card className="mb-3">
                                    <Card.Body>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Format</th>
                                                    <th>Overs</th>
                                                    <th>Wickets</th>
                                                    <th>Average</th>
                                                    <th>Economy Rate</th>
                                                    <th>Best Bowling</th>
                                                    <th>Matches</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {playerProfile.bowlingStats && playerProfile.bowlingStats.length > 0 ? (
                                                    playerProfile.bowlingStats.map((stat, index) => (
                                                        <tr key={index}>
                                                            <td>{stat.format}</td>
                                                            <td>{stat.overs || '-'}</td>
                                                            <td>{stat.wickets || '-'}</td>
                                                            <td>{stat.average || '-'}</td>
                                                            <td>{stat.economyRate || '-'}</td>
                                                            <td>{stat.bestBowling || '-'}</td>
                                                            <td>{stat.matches || '-'}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="7">No bowling stats available.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>

                                <h5 className="mb-3">Player Information</h5>
                                <Card className="mb-3">
                                    <Card.Body>
                                        <div>{plainText || 'No description available.'}</div>
                                    </Card.Body>
                                </Card>
                            </>
                        ) : null}
                    </Col>

                    <Col md={4}>
                        {playerProfile ? (
                            <Card className="mb-4 mt-4">
                                <Card.Body>
                                    <Row className="mb-2">
                                        <Col>
                                            <h6>Name</h6>
                                            <h6>Birth Place</h6>
                                            <h6>Role</h6>
                                            <h6>Batting Style</h6>
                                            <h6>Bowling Style</h6>
                                            <h6>Age</h6>
                                        </Col>
                                        <Col xs="auto">
                                            <h6>{playerProfile.name || '-'}</h6>
                                            <h6>{playerProfile.birthPlace || '-'}</h6>
                                            <h6>{playerProfile.role || '-'}</h6>
                                            <h6>{playerProfile.battingStyle || '-'}</h6>
                                            <h6>{playerProfile.bowlingStyle || '-'}</h6>
                                            <h6>{age !== null ? age  : '-'}</h6>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        ) : null}
                        <Ads />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default PlayerInfo;
