import { Card, Col, Container, Row } from "react-bootstrap";
import Header from "../components/Header";
import '../css/style.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Ads from "../components/ads";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Series() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [schedule, setSchedule] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSchedule = async () => {
            const apiUrl = 'https://apiv2.cricket.com/cricket/';
            const query = {
                query: `query {
                    newSchedule(type: "all") {
                        seriesName
                        league
                        seriesID
                        seriesAvailable
                        seriesView
                    }
                }`
            };
            setLoading(true);
            try {
                const response = await axios.post(apiUrl, query, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                // Store the schedule data in the state
                setSchedule(response.data.data.newSchedule || []);
                setLoading(false);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchSchedule();
    }, []);

    if (error) return <div>Error: {error}</div>;

    return (
        <>
        <Helmet>
                <title>Cricball - Series Matches</title>
                <meta name="description" content="Get Live Cricket Scores, Ball by Ball Commentary, Scorecard Updates, Match Facts &amp; related News of all the International &amp; Domestic Cricket Matches across the globe." />
                <meta name="keywords" content="Cricball, 1 Ball Ago, Live Score, Live Cricket Matches" />
            </Helmet>
            <Header />
            <Container className="mt-3">
                <Row>
                    <h4 className='pb-3'>Domestic & International Cricket Series</h4>
                    <Col md={8}>
                        <div className="series-list">
                            {schedule && schedule.map((series, index) => (
                                <div key={index} className="series-item" onClick={() => navigate(`/SeriesDetails?SeriesID=${series.seriesID}`)}>
                                    <span role="img" aria-label="trophy">🏆</span> {loading ? 'Cricket Series' : series.seriesName}
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col md={4} className='col-md-4'>
                        <Ads />
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default Series;