import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function Header() {
  const location = useLocation(); // Get the current location

  return (
    <div className='container-fluid p-0 headerbg'>
      <div className='container'>
        <nav className="navbar navbar-expand-lg navbar-dark headerbg">
          <Link className="navbar-brand" to="/">CricBall</Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className={`nav-item ${location.pathname === '/livescore' ? 'active' : ''}`}>
                <Link className="nav-link" to="/livescore">Live Score</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/schedule' ? 'active' : ''}`}>
                <Link className="nav-link" to="/schedule">Schedule</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/completed' ? 'active' : ''}`}>
                <Link className="nav-link" to="/completed">Completed</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/series' ? 'active' : ''}`}>
                <Link className="nav-link" to="/series">Series</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/ranking' ? 'active' : ''}`}>
                <Link className="nav-link" to="/ranking">Rankings</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
