import { Card } from "react-bootstrap";
 import '../css/style.css';

function Ads() {
    return (
        <>
            <Card>
                <Card.Body className="text-center">
                    <h4>ADS</h4>
                </Card.Body>
            </Card>
        </>
    );
}

export default Ads;