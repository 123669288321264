import Header from "../components/Header";
import { useSearchParams } from 'react-router-dom';
import { Col, Container, Row, Card, Table, Tab, Nav } from 'react-bootstrap';
import '../css/style.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Ads from "../components/ads";
import profile from '../assets/images/profile.png';
import { TeamImageURL, TeamImageextension, Player2ImageURL, PlayerImageExtension } from '../components/global';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function SeriesScorecard() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const DYMatchId = searchParams.get('matchID');

    const [seriesMatches, setSeriesMatches] = useState([]); // Initialize as an empty array
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const [innerActiveTab, setInnerActiveTab] = useState('teamA');

    useEffect(() => {
        const fetchSeriesCardMatches = async () => {
            const apiUrl = 'https://apiv2.cricket.com/cricket/';
            const query = {
                query: `
                  query {
                    getScoreCard(matchID: "${DYMatchId}") {
                      fullScoreCard {
                        battingTeamName
                        battingTeamShortName
                        battingTeamID
                        total {
                          overs
                          runsScored
                          wickets
                          runRate
                        }
                        batting {
                          playerName
                          playerMatchSixes
                          playerMatchBalls
                          playerMatchRuns
                          playerBattingNumber
                          playerMatchFours
                          playerHowOut
                          playerID
                          playerMatchStrikeRate
                          isCaptain
                        }
                        runsScored
                      }
                    }
                  }
                `
            };
            setLoading(true);

            try {
                const response = await axios.post(apiUrl, query, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setSeriesMatches(response.data.data.getScoreCard.fullScoreCard || []); // Ensure it's an array
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchSeriesCardMatches();
    }, [DYMatchId]);

    const handleImageError = (e) => {
        e.target.src = profile; // Fallback image
    };

    const renderTeamInfo = (team) => (
        <Row className="align-items-center mb-3">
            <Col xs={2} className="d-flex justify-content-center">
                <img
                    src={`${TeamImageURL}${team.battingTeamID}${TeamImageextension}`}
                    alt={team.battingTeamName}
                    onError={handleImageError}
                    className="rounded-circle"
                    style={{ width: '40px', height: '40px' }}
                />
            </Col>
            <Col>
                <strong>{team.battingTeamName}</strong>
            </Col>
            <Col style={{ textAlign: 'right' }}>
                <strong>{team.total ? `${team.total.runsScored}/${team.total.wickets}` : '0/0'}</strong> in
                <strong> {team.total ? `(${team.total.overs})` : '0.0'}</strong>
            </Col>
        </Row>
    );

    return (
        <>
         <Helmet>
                <title>Cricball - Series Scorecard</title>
                <meta name="description" content="Get Live Cricket Scores, Ball by Ball Commentary, Scorecard Updates, Match Facts &amp; related News of all the International &amp; Domestic Cricket Matches across the globe." />
                <meta name="keywords" content="Cricball, 1 Ball Ago, Live Score, Live Cricket Matches" />
            </Helmet>
            <Header />
            <Container>
                <Row>
                    <Col md={8} className='pt-3 pb-3'>
                        {loading ? (
                            <Card className='text-center'>
                                <Card.Body>
                                    <p>Loading...</p>
                                </Card.Body>
                            </Card>
                        ) : seriesMatches.length === 0 ? (
                            <Card className='text-center'>
                                <Card.Body>
                                    <p>No Data Found</p>
                                </Card.Body>
                            </Card>
                        ) : error ? (
                            <Card className='text-center'>
                                <Card.Body>
                                    <p>Error: {error}</p>
                                </Card.Body>
                            </Card>
                        )  : (
                            <Card className='mt-1' style={{ width: '100%', borderRadius: '15px', padding: '15px', border: 'none' }}>
                                {/* Team Info */}
                                {seriesMatches[0] && renderTeamInfo(seriesMatches[0])}
                                {seriesMatches.length > 1 && seriesMatches[1] && renderTeamInfo(seriesMatches[1])}
                            </Card>
                        )}

                        <Tab.Container id="inner-tabs" defaultActiveKey="teamA">
                            <Nav variant="tabs" className="mb-3 mt-3">
                                <Nav.Item>
                                    <Nav.Link eventKey="teamA" onClick={() => setInnerActiveTab('teamA')}>Team A</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="teamB" onClick={() => setInnerActiveTab('teamB')}>Team B</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="teamA" active={innerActiveTab === 'teamA'}>
                                    <Card className="mb-3">
                                        <Card.Body>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th>R</th>
                                                        <th>B</th>
                                                        <th>4s</th>
                                                        <th>6s</th>
                                                        <th>SR</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {seriesMatches[0]?.batting?.length > 0 ? (
                                                        seriesMatches[0].batting.map((player, index) => (
                                                            <tr key={index} onClick={() => navigate(`/Playerinfo?PlayerID=${player.playerID}`)}>
                                                                <td>
                                                                    <img
                                                                        src={`${Player2ImageURL}${player.playerID}${PlayerImageExtension}`}
                                                                        width={40}
                                                                        height={40}
                                                                        style={{ borderRadius: '100px' }}
                                                                        onError={handleImageError}
                                                                        alt={`${player.playerName}`}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {player.playerName} {player.isCaptain ? '(C)' : ""}
                                                                    <br />
                                                                    <span>{player.playerHowOut}</span>
                                                                </td>
                                                                <td>{player.playerMatchRuns}</td>
                                                                <td>{player.playerMatchBalls}</td>
                                                                <td>{player.playerMatchFours}</td>
                                                                <td>{player.playerMatchSixes}</td>
                                                                <td>{player.playerMatchStrikeRate}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="7" className="text-center">No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="teamB" active={innerActiveTab === 'teamB'}>
                                    <Card className="mb-3">
                                        <Card.Body>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th>R</th>
                                                        <th>B</th>
                                                        <th>4s</th>
                                                        <th>6s</th>
                                                        <th>SR</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {seriesMatches.length > 1 && seriesMatches[1]?.batting?.length > 0 ? (
                                                        seriesMatches[1].batting.map((player, index) => (
                                                            <tr key={index} onClick={() => navigate(`/Playerinfo?PlayerID=${player.playerID}`)}>
                                                                <td>
                                                                    <img
                                                                        src={`${Player2ImageURL}${player.playerID}${PlayerImageExtension}`}
                                                                        width={40}
                                                                        height={40}
                                                                        style={{ borderRadius: '100px' }}
                                                                        onError={handleImageError}
                                                                        alt={`${player.playerName}`}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {player.playerName} {player.isCaptain ? '(C)' : ""}
                                                                    <br />
                                                                    <span>{player.playerHowOut}</span>
                                                                </td>
                                                                <td>{player.playerMatchRuns}</td>
                                                                <td>{player.playerMatchBalls}</td>
                                                                <td>{player.playerMatchFours}</td>
                                                                <td>{player.playerMatchSixes}</td>
                                                                <td>{player.playerMatchStrikeRate}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="7" className="text-center">No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>

                    <Col md={4} className='pt-3'>
                        <Ads />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SeriesScorecard;
