import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from './pages/dashboard';
import Liveline from './pages/Liveline';
import Livescore from './pages/livescore';
import Schedule from './pages/schedule';
import CompletedMatch from './pages/completed';
import Series from './pages/series';
import SeriesDetails from './pages/seriesdetail';
import SeriesScorecard from './pages/seriesscorecard';
import PlayerInfo from './pages/playerinfo';
import Ranking from './pages/ranking';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/liveline" element={<Liveline />} />
        <Route path="/livescore" element={<Livescore />} />
        <Route path="/Schedule" element={<Schedule />} />
        <Route path="/Completed" element={<CompletedMatch />} />
        <Route path="/series" element={<Series />} />
        <Route path="/SeriesDetails" element={<SeriesDetails />} />
        <Route path="/SeriesScore" element={<SeriesScorecard />} />
        <Route path="/Playerinfo" element={<PlayerInfo />} />
        <Route path="/Ranking" element={<Ranking />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
