import React, { useEffect, useState } from 'react';
import '../../css/style.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Serieslist() {
    const [loading, setLoading] = useState(true);
    const [schedule, setSchedule] = useState(null);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchSchedule = async () => {
            const apiUrl = 'https://apiv2.cricket.com/cricket/';
            const query = {
                query: `query {
                    newSchedule(type: "all") {
                        seriesName
                        league
                        seriesID
                        seriesAvailable
                        seriesView
                    }
                }`
            };
            setLoading(true);
            try {
                const response = await axios.post(apiUrl, query, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                // Store the schedule data in the state
                setSchedule(response.data.data.newSchedule);
                setLoading(false);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchSchedule();
    }, []);

    if (error) return <div>Error: {error}</div>;

    return (
        <div className='container'>
            <div className='row'>
                <h4 className='pb-3'>Domestic & International Cricket Series</h4>
                <div className='col-md-6'>
                    <div className="series-list">
                        {schedule && schedule.slice(0, Math.ceil(schedule.length / 2)).map((series, index) => (
                            <div key={index} className="series-item" onClick={() => navigate(`/SeriesDetails?SeriesID=${series.seriesID}`)}>
                                <span role="img" aria-label="trophy">🏆</span> {loading ? 'Cricket Series' : series.seriesName}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="series-list">
                        {schedule && schedule.slice(Math.ceil(schedule.length / 2)).map((series, index) => (
                            <div key={index} className="series-item" onClick={() => navigate(`/SeriesDetails?SeriesID=${series.seriesID}`)}>
                                <span role="img" aria-label="trophy">🏆</span>  {loading ? 'Cricket Series' : series.seriesName}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Serieslist;
