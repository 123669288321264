import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import '../css/style.css';
import axios from 'axios';
import { Col, Container, Row, Card, CardBody } from 'react-bootstrap';
import profile from '../assets/images/profile.png';
import { useNavigate } from 'react-router-dom';
import Ads from '../components/ads';
import { Helmet } from 'react-helmet';



function CompletedMatch() {

   

    const navigate = useNavigate();

    const [completedMatches, setCompletedMatches] = useState(Array(3).fill({ Title: '', TeamA: '', team1Score: '', TeamB: '', team2Score: '', Matchtime: '' }));
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchcompletedMatches = async () => {
            setLoading(true);
            try {
                const targetUrl = 'https://api.cricball.in/MatchResults.php';
                const response = await axios.post(targetUrl, {
                    start: '1',
                    end: '20'
                });
                setCompletedMatches(response.data.AllMatch || []);
            } catch (error) {
                if (error.response) {
                    setError(`Error: ${error.response.status} - ${error.response.data}`);
                } else if (error.request) {
                    setError('Network Error: No response received from server');
                } else {
                    setError(`Error: ${error.message}`);
                }
            } finally {
                setLoading(false);
            }
        };
        fetchcompletedMatches();
    }, []);


    const handleImageError = (e) => {
        e.target.src = profile; // Fallback image
    };


    return (
        <>
            <Helmet>
                <title>Cricball - Completed Matches</title>
                <meta name="description" content="Get Live Cricket Scores, Ball by Ball Commentary, Scorecard Updates, Match Facts &amp; related News of all the International &amp; Domestic Cricket Matches across the globe." />
                <meta name="keywords" content="Cricball, 1 Ball Ago, Live Score, Live Cricket Matches" />
            </Helmet>

            <Header />
            <Container>
                <Row>
                    <Col md={8} className='pt-3 pb-3'>

                        {completedMatches.length > 0 ? (
                            completedMatches.map((match, index) => {
                                let teamAImage = match.ImageUrl + match.TeamAImage;
                                let teamBImage = match.ImageUrl + match.TeamBImage;

                                return (
                                    <Card
                                        className='mt-3'
                                        key={index}
                                        style={{ width: '100%', borderRadius: '15px', padding: '15px', border: 'none' }}
                                        onClick={() => navigate(`/liveline?MatchId=${match.MatchId}`)}
                                    >
                                        {/* League and Date Row */}
                                        <Row className="mb-2">
                                            <Col>
                                                <h6 style={{ margin: 0 }}>{loading ? 'Match Name' : match.Title}</h6>
                                            </Col>
                                            <Col xs="auto">
                                                <span style={{ color: '#0d6efd', fontWeight: 'bold' }}>Completed</span>
                                            </Col>
                                        </Row>

                                        {/* Teams Row */}
                                        <Row className="align-items-center mb-3">
                                            <Col xs={2} className="d-flex justify-content-center">
                                                <img
                                                    src={loading ? profile : teamAImage.startsWith('http://') ? profile : teamAImage}
                                                    alt={teamAImage}
                                                    onError={handleImageError}
                                                    className="rounded-circle"
                                                    style={{ width: '40px', height: '40px' }}
                                                />
                                            </Col>
                                            <Col>
                                                <strong>{loading ? 'Team A' : match.TeamA}</strong>
                                            </Col>
                                        </Row>

                                        <Row className="align-items-center mb-2">
                                            <Col xs={2} className="d-flex justify-content-center">
                                                <img
                                                    src={loading ? profile : teamBImage.startsWith('http://') ? profile : teamBImage}
                                                    alt={teamBImage}
                                                    onError={handleImageError}
                                                    className="rounded-circle"
                                                    style={{ width: '40px', height: '40px' }}
                                                />
                                            </Col>
                                            <Col>
                                                <strong>{loading ? 'Team B' : match.TeamB}</strong>
                                            </Col>
                                        </Row>

                                        {/* Date and Time */}
                                        <Row className="text-center">
                                            <Col>
                                                <p className="text-muted mb-0">{loading ? 'Match Time at 00:00 AM/PM' : match.Result}</p>
                                            </Col>
                                        </Row>
                                    </Card>
                                );
                            })
                        ) : (
                            <Card className='mt-3'>
                                <CardBody>
                                    <p>No data found</p>
                                </CardBody>
                            </Card>
                        )}

                    </Col>

                    <Col md={4} className='pt-3'>
                        <Ads />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CompletedMatch;