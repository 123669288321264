import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../css/style.css';
import profile from '../../assets/images/profile.png'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';


function Dashboardslider() {

    const navigate = useNavigate();

    const [liveMatches, setLiveMatches] = useState(Array(3).fill({ Title: '', TeamA: '', team1Score: '', TeamB: '', team2Score: '', Matchtime: '' }));
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    useEffect(() => {
        const fetchLiveMatches = async () => {
            setLoading(true);
            try {
                const targetUrl = 'https://api.cricball.in/liveline.php';
                const response = await axios.get(targetUrl);
                setLiveMatches(response.data);
            } catch (error) {
                if (error.response) {
                    setError(`Error: ${error.response.status} - ${error.response.data}`);
                } else if (error.request) {
                    setError('Network Error: No response received from server');
                } else {
                    setError(`Error: ${error.message}`);
                }
            } finally {
                setLoading(false);
            }
        };
        fetchLiveMatches();
    }, []);


    const formatDate = (dateStr) => {
        if (!dateStr) return null; // Safeguard for empty or undefined date
        const parts = dateStr.split('-');
        if (parts.length !== 3) return null; // Ensure the date format is valid
        const [day, month, year] = parts;
        return new Date(`${month}/${day}/${year}`);
    };


   
    return (
        <>
            <div className='container'>
                <div className="live-matches" style={{ overflow: 'hidden' }}>
                    {error ? (
                        <p style={{ color: 'red' }}>{error}</p>
                    ) : (
                        <Slider {...settings}>
                            {liveMatches.map((match, index) => {
                                let teamAImage = match.ImgeURL + match.TeamAImage;
                                let teamBImage = match.ImgeURL + match.TeamBImage;
                                const matchDate = formatDate(match.MatchDate);
                                const today = new Date();
                                const isToday = matchDate && today.toDateString() === matchDate.toDateString();
                                const isResult = /won by/i.test(match.Result);

                                if (match.jsondata !== undefined && match.jsondata !== "") {
                                    let newJsonData = match.jsondata;
                                    newJsonData = newJsonData.replace(/[\n\t\r]/g, ' ');
                                    const parseJson = JSON.parse(newJsonData);
                                    const isLive = parseJson.jsondata.batsman !== "*|";

                                    const matchStatus = isResult
                                        ? 'Result'
                                        : isLive
                                            ? 'Live'
                                            : isToday
                                                ? 'Today'
                                                : 'Upcoming';

                                    return (
                                        <div key={index} className="item match-card" onClick={() => navigate(`/liveline?MatchId=${match.MatchId}`)}>
                                            <div className="match-info1">
                                                <p className='mb-0'>{loading ? 'Match Name' : match.Title}</p>
                                                <p className='mb-0'>{loading ? 'Status' : matchStatus}</p>

                                            </div>
                                            <div className="match-info">
                                                <div className='teamA d-flex align-center'>
                                                    <img src={loading ? profile :  teamAImage.startsWith('http://') ? profile : teamAImage} style={{ borderRadius: '100px', marginRight: '10px', width: '30px' }} onError={(e) => e.target.src = profile} />
                                                    <p className='mb-0'>{loading ? 'Team A' : match.TeamA}</p>
                                                </div>

                                                <p className='mb-0'>{loading ? '(0/0)' : `(${parseJson.jsondata.wicketA})`}</p>
                                            </div>
                                            <div className="match-info">
                                                <div className='teamA d-flex align-center'>
                                                    <img src={loading ? profile : teamBImage.startsWith('http://') ? profile : teamBImage} style={{ borderRadius: '100px', marginRight: '10px', width: '30px' }} onError={(e) => e.target.src = profile} />
                                                    <p className='mb-0'>{loading ? 'Team B' : match.TeamB}</p>
                                                </div>
                                                <p className='mb-0'>{loading ? '(0/0)' : `(${parseJson.jsondata.wicketB})`}</p>
                                            </div>
                                            <div className="match-date">
                                                <p className='mb-0'>{loading ? 'Match Time at 00:00 AM/PM' : isResult ? match.Result : match.Matchtime}</p>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    const matchStatus = isResult
                                        ? 'Result'
                                        : isToday
                                            ? 'Today'
                                            : 'Upcoming';
                                    return (
                                        <div key={index} className="item match-card" onClick={() => navigate(`/liveline?MatchId=${match.MatchId}`)}>
                                            <div className="match-info1">
                                                <p className='mb-0'>{loading ? 'Match Title' : match.Title}</p>
                                                <p className='mb-0'>{loading ? 'Status' : matchStatus}</p>
                                            </div>
                                            <div className="match-info">
                                                <div className='teamA d-flex align-center'>
                                                    <img src={loading ? profile : teamAImage.startsWith('http://') ? profile : teamAImage} style={{ borderRadius: '100px', marginRight: '10px', width: '30px' }} onError={(e) => e.target.src = profile} />
                                                    <p className='mb-0'>{loading ? 'Team A' : match.TeamA}</p>
                                                </div>
                                                <p className='mb-0'>{loading ? '(0/0)' : `(0/0)`}</p>
                                            </div>
                                            <div className="match-info">
                                                <div className='teamA d-flex align-center'>
                                                    <img src={loading ? profile : teamBImage.startsWith('http://') ? profile : teamBImage} style={{ borderRadius: '100px', marginRight: '10px', width: '30px' }} onError={(e) => e.target.src = profile}  />
                                                    <p className='mb-0'>{loading ? 'Team B' : match.TeamB}</p>
                                                </div>
                                                <p className='mb-0'>{loading ? '(0/0)' : `(0/0)`}</p>
                                            </div>
                                            <div className="match-date">
                                                <p className='mb-0'>{loading ? 'Match Time at 00:00 AM/PM' : match.Matchtime}</p>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </Slider>
                    )}
                </div>
            </div>
        </>
    );
}

export default Dashboardslider;
