import { useState, useEffect } from 'react';
import '../css/style.css';
import Header from '../components/Header';
import { Table, Card, Tab, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Ads from '../components/ads';
import axios from 'axios';
import { TeamImageURL, TeamImageextension, Player2ImageURL, PlayerImageExtension } from '../components/global';
import profile from '../assets/images/profile.png';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Ranking() {

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('batting');
    const [innerActiveTab1, setInnerActiveTab1] = useState('odi');
    const [innerActiveTab2, setInnerActiveTab2] = useState('odi');
    const [innerActiveTab3, setInnerActiveTab3] = useState('odi');
    const [innerActiveTab4, setInnerActiveTab4] = useState('odi');

    const [loading, setLoading] = useState(true);
    const [ranking, setRanking] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSchedule = async () => {
            const apiUrl = 'https://apiv2.cricket.com/cricket/';
            const query = {
                query: `
                  query {
                    getPlayerRankings {
                      T20 {
                        men {
                          allrounder {
                            rank {
                              position
                              playerName
                              Points
                              playerID
                              teamName
                              teamID
                            }
                          }
                          bat {
                            rank {
                              position
                              playerName
                              Points
                              playerID
                              teamName
                              teamID
                            }
                          }
                          bowl {
                            rank {
                              position
                              playerName
                              Points
                              playerID
                              teamName
                              teamID
                            }
                          }
                          team {
                            rank {
                              teamName
                              teamID
                              Rating
                              position
                            }
                          }
                        }
                      }
                      ODI {
                        men {
                          allrounder {
                            rank {
                              position
                              playerName
                              Points
                              playerID
                              teamName
                              teamID
                            }
                          }
                          bat {
                            rank {
                              position
                              playerName
                              Points
                              playerID
                              teamName
                              teamID
                            }
                          }
                          bowl {
                            rank {
                              position
                              playerName
                              Points
                              playerID
                              teamName
                              teamID
                            }
                          }
                          team {
                            rank {
                              teamName
                              teamID
                              Rating
                              position
                            }
                          }
                        }
                      }
                      Test {
                        men {
                          allrounder {
                            rank {
                              position
                              playerName
                              Points
                              playerID
                              teamName
                              teamID
                            }
                          }
                          bat {
                            rank {
                              position
                              playerName
                              Points
                              playerID
                              teamName
                              teamID
                            }
                          }
                          bowl {
                            rank {
                              position
                              playerName
                              Points
                              playerID
                              teamName
                              teamID
                            }
                          }
                          team {
                            rank {
                              teamName
                              teamID
                              Rating
                              position
                            }
                          }
                        }
                      }
                    }
                  }
                `
            };
            setLoading(true);
            try {
                const response = await axios.post(apiUrl, query, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                // Store the schedule data in the state
                setRanking(response.data.data.getPlayerRankings || []);
                setLoading(false);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchSchedule();
    }, []);

    const handleImageError = (e) => {
        e.target.src = profile; // Fallback image
    };

    return (
        <>
            <Helmet>
                <title>Cricball - Player Ranking Information</title>
                <meta name="description" content="Get Live Cricket Scores, Ball by Ball Commentary, Scorecard Updates, Match Facts &amp; related News of all the International &amp; Domestic Cricket Matches across the globe." />
                <meta name="keywords" content="Cricball, 1 Ball Ago, Live Score, Live Cricket Matches" />
            </Helmet>

            <Header />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-8 pt-3'>
                        <Nav variant="tabs" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)}>
                            <Nav.Item>
                                <Nav.Link eventKey="batting">Batting</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="bowling">Bowling</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="allrounder">All Rounder</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="teams">Teams</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        {/* Tab Content */}
                        <Tab.Content className="mt-4">
                            <Tab.Pane eventKey="batting" active={activeTab === 'batting'}>
                                <Tab.Container id="inner-tabs" defaultActiveKey="odi">
                                    <Nav variant="tabs" className="mb-3">
                                        <Nav.Item>
                                            <Nav.Link eventKey="odi" onClick={() => setInnerActiveTab1('odi')}>ODI</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="t20" onClick={() => setInnerActiveTab1('t20')}>T20</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="test" onClick={() => setInnerActiveTab1('test')}>TEST</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="odi" active={innerActiveTab1 === 'odi'}>
                                            {/* Content for Batting */}
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Player</th>
                                                                <th>Flags</th>
                                                                <th>Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loading && !error && ranking && ranking.ODI && ranking.ODI.men.bat.rank.length > 0 &&
                                                                ranking.ODI.men.bat.rank.map((rank, index) => (
                                                                    <tr key={index} onClick={() => navigate(`/Playerinfo?PlayerID=${rank.playerID}`)}>
                                                                        <td>{rank.position}</td>
                                                                        <td style={{ display: 'flex' }}><img src={`${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`.startsWith('http://') ? profile : `${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`} style={{
                                                                            width: '40px', height: '40px', borderRadius: '100px', marginRight: '20px'
                                                                        }} onError={handleImageError} />
                                                                            <div>
                                                                                {rank.playerName} <br></br>{rank.teamName}
                                                                            </div></td>
                                                                        <td><img src={`${TeamImageURL}${rank.teamID}${TeamImageextension}`.startsWith('http://') ? profile : `${TeamImageURL}${rank.teamID}${TeamImageextension}`} style={{
                                                                            width: '30px', height: '20px',
                                                                        }} onError={handleImageError} /></td>
                                                                        <td>{rank.Points}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="t20" active={innerActiveTab1 === 't20'}>
                                            {/* Content for Bowling */}
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Player</th>
                                                                <th>Flags</th>
                                                                <th>Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loading && !error && ranking && ranking.T20 && ranking.T20.men.bat.rank.length > 0 &&
                                                                ranking.T20.men.bat.rank.map((rank, index) => (
                                                                    <tr key={index} onClick={() => navigate(`/Playerinfo?PlayerID=${rank.playerID}`)}>
                                                                        <td>{rank.position}</td>
                                                                        <td style={{ display: 'flex' }}><img src={`${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`.startsWith('http://') ? profile : `${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`} style={{
                                                                            width: '40px', height: '40px', borderRadius: '100px', marginRight: '20px'
                                                                        }} onError={handleImageError} />
                                                                            <div>
                                                                                {rank.playerName} <br></br>{rank.teamName}
                                                                            </div></td>
                                                                        <td><img src={`${TeamImageURL}${rank.teamID}${TeamImageextension}`.startsWith('http://') ? profile : `${TeamImageURL}${rank.teamID}${TeamImageextension}`} style={{
                                                                            width: '30px', height: '20px',
                                                                        }} onError={handleImageError} /></td>
                                                                        <td>{rank.Points}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="test" active={innerActiveTab1 === 'test'}>
                                            {/* Content for Bowling */}
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Player</th>
                                                                <th>Flags</th>
                                                                <th>Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loading && !error && ranking && ranking.Test && ranking.Test.men.bat.rank.length > 0 &&
                                                                ranking.Test.men.bat.rank.map((rank, index) => (
                                                                    <tr key={index} onClick={() => navigate(`/Playerinfo?PlayerID=${rank.playerID}`)}>
                                                                        <td>{rank.position}</td>
                                                                        <td style={{ display: 'flex' }}><img src={`${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`.startsWith('http://') ? profile : `${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`} style={{
                                                                            width: '40px', height: '40px', borderRadius: '100px', marginRight: '20px'
                                                                        }} onError={handleImageError} />
                                                                            <div>
                                                                                {rank.playerName} <br></br>{rank.teamName}
                                                                            </div></td>
                                                                        <td><img src={`${TeamImageURL}${rank.teamID}${TeamImageextension}`.startsWith('http://') ? profile : `${TeamImageURL}${rank.teamID}${TeamImageextension}`} style={{
                                                                            width: '30px', height: '20px',
                                                                        }} onError={handleImageError} /></td>
                                                                        <td>{rank.Points}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Tab.Pane>

                            <Tab.Pane eventKey="bowling" active={activeTab === 'bowling'}>

                                <Tab.Container id="inner-tabs" defaultActiveKey="odi">
                                    <Nav variant="tabs" className="mb-3">
                                        <Nav.Item>
                                            <Nav.Link eventKey="odi" onClick={() => setInnerActiveTab2('odi')}>ODI</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="t20" onClick={() => setInnerActiveTab2('t20')}>T20</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="test" onClick={() => setInnerActiveTab2('test')}>TEST</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="odi" active={innerActiveTab2 === 'odi'}>
                                            {/* Content for Batting */}
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Player</th>
                                                                <th>Flags</th>
                                                                <th>Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loading && !error && ranking && ranking.ODI && ranking.ODI.men.bowl.rank.length > 0 &&
                                                                ranking.ODI.men.bowl.rank.map((rank, index) => (
                                                                    <tr key={index} onClick={() => navigate(`/Playerinfo?PlayerID=${rank.playerID}`)}>
                                                                        <td>{rank.position}</td>
                                                                        <td style={{ display: 'flex' }}><img src={`${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`.startsWith('http://') ? profile : `${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`} style={{
                                                                            width: '40px', height: '40px', borderRadius: '100px', marginRight: '20px'
                                                                        }} onError={handleImageError} />
                                                                            <div>
                                                                                {rank.playerName} <br></br>{rank.teamName}
                                                                            </div></td>
                                                                        <td><img src={`${TeamImageURL}${rank.teamID}${TeamImageextension}`.startsWith('http://') ? profile : `${TeamImageURL}${rank.teamID}${TeamImageextension}`} style={{
                                                                            width: '30px', height: '20px',
                                                                        }} onError={handleImageError} /></td>
                                                                        <td>{rank.Points}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="t20" active={innerActiveTab2 === 't20'}>
                                            {/* Content for Bowling */}
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Player</th>
                                                                <th>Flags</th>
                                                                <th>Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loading && !error && ranking && ranking.T20 && ranking.T20.men.bowl.rank.length > 0 &&
                                                                ranking.T20.men.bowl.rank.map((rank, index) => (
                                                                    <tr key={index} onClick={() => navigate(`/Playerinfo?PlayerID=${rank.playerID}`)}>
                                                                        <td>{rank.position}</td>
                                                                        <td style={{ display: 'flex' }}><img src={`${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`.startsWith('http://') ? profile : `${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`} style={{
                                                                            width: '40px', height: '40px', borderRadius: '100px', marginRight: '20px'
                                                                        }} onError={handleImageError} />
                                                                            <div>
                                                                                {rank.playerName} <br></br>{rank.teamName}
                                                                            </div></td>
                                                                        <td><img src={`${TeamImageURL}${rank.teamID}${TeamImageextension}`.startsWith('http://') ? profile : `${TeamImageURL}${rank.teamID}${TeamImageextension}`} style={{
                                                                            width: '30px', height: '20px',
                                                                        }} onError={handleImageError} /></td>
                                                                        <td>{rank.Points}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="test" active={innerActiveTab2 === 'test'}>
                                            {/* Content for Bowling */}
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Player</th>
                                                                <th>Flags</th>
                                                                <th>Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loading && !error && ranking && ranking.Test && ranking.Test.men.bowl.rank.length > 0 &&
                                                                ranking.Test.men.bowl.rank.map((rank, index) => (
                                                                    <tr key={index} onClick={() => navigate(`/Playerinfo?PlayerID=${rank.playerID}`)}>
                                                                        <td>{rank.position}</td>
                                                                        <td style={{ display: 'flex' }}><img src={`${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`.startsWith('http://') ? profile : `${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`} style={{
                                                                            width: '40px', height: '40px', borderRadius: '100px', marginRight: '20px'
                                                                        }} onError={handleImageError} />
                                                                            <div>
                                                                                {rank.playerName} <br></br>{rank.teamName}
                                                                            </div></td>
                                                                        <td><img src={`${TeamImageURL}${rank.teamID}${TeamImageextension}`.startsWith('http://') ? profile : `${TeamImageURL}${rank.teamID}${TeamImageextension}`} style={{
                                                                            width: '30px', height: '20px',
                                                                        }} onError={handleImageError} /></td>
                                                                        <td>{rank.Points}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Tab.Pane>

                            <Tab.Pane eventKey="allrounder" active={activeTab === 'allrounder'}>

                                <Tab.Container id="inner-tabs" defaultActiveKey="odi">
                                    <Nav variant="tabs" className="mb-3">
                                        <Nav.Item>
                                            <Nav.Link eventKey="odi" onClick={() => setInnerActiveTab3('odi')}>ODI</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="t20" onClick={() => setInnerActiveTab3('t20')}>T20</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="test" onClick={() => setInnerActiveTab3('test')}>TEST</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="odi" active={innerActiveTab3 === 'odi'}>
                                            {/* Content for Batting */}
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Player</th>
                                                                <th>Flags</th>
                                                                <th>Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loading && !error && ranking && ranking.ODI && ranking.ODI.men.allrounder.rank.length > 0 &&
                                                                ranking.ODI.men.allrounder.rank.map((rank, index) => (
                                                                    <tr key={index} onClick={() => navigate(`/Playerinfo?PlayerID=${rank.playerID}`)}>
                                                                        <td>{rank.position}</td>
                                                                        <td style={{ display: 'flex' }}><img src={`${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`.startsWith('http://') ? profile : `${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`} style={{
                                                                            width: '40px', height: '40px', borderRadius: '100px', marginRight: '20px'
                                                                        }} onError={handleImageError} />
                                                                            <div>
                                                                                {rank.playerName} <br></br>{rank.teamName}
                                                                            </div></td>
                                                                        <td><img src={`${TeamImageURL}${rank.teamID}${TeamImageextension}`.startsWith('http://') ? profile : `${TeamImageURL}${rank.teamID}${TeamImageextension}`} style={{
                                                                            width: '30px', height: '20px',
                                                                        }} onError={handleImageError} /></td>
                                                                        <td>{rank.Points}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="t20" active={innerActiveTab3 === 't20'}>
                                            {/* Content for Bowling */}
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Player</th>
                                                                <th>Flags</th>
                                                                <th>Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loading && !error && ranking && ranking.T20 && ranking.T20.men.allrounder.rank.length > 0 &&
                                                                ranking.T20.men.allrounder.rank.map((rank, index) => (
                                                                    <tr key={index} onClick={() => navigate(`/Playerinfo?PlayerID=${rank.playerID}`)}>
                                                                        <td>{rank.position}</td>
                                                                        <td style={{ display: 'flex' }}><img src={`${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`.startsWith('http://') ? profile : `${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`} style={{
                                                                            width: '40px', height: '40px', borderRadius: '100px', marginRight: '20px'
                                                                        }} onError={handleImageError} />
                                                                            <div>
                                                                                {rank.playerName} <br></br>{rank.teamName}
                                                                            </div></td>
                                                                        <td><img src={`${TeamImageURL}${rank.teamID}${TeamImageextension}`.startsWith('http://') ? profile : `${TeamImageURL}${rank.teamID}${TeamImageextension}`} style={{
                                                                            width: '30px', height: '20px',
                                                                        }} onError={handleImageError} /></td>
                                                                        <td>{rank.Points}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="test" active={innerActiveTab3 === 'test'}>
                                            {/* Content for Bowling */}
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Player</th>
                                                                <th>Flags</th>
                                                                <th>Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loading && !error && ranking && ranking.Test && ranking.Test.men.allrounder.rank.length > 0 &&
                                                                ranking.Test.men.allrounder.rank.map((rank, index) => (
                                                                    <tr key={index} onClick={() => navigate(`/Playerinfo?PlayerID=${rank.playerID}`)}>
                                                                        <td>{rank.position}</td>
                                                                        <td style={{ display: 'flex' }}><img src={`${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`.startsWith('http://') ? profile : `${Player2ImageURL}${rank.playerID}${PlayerImageExtension}`} style={{
                                                                            width: '40px', height: '40px', borderRadius: '100px', marginRight: '20px'
                                                                        }} onError={handleImageError} />
                                                                            <div>
                                                                                {rank.playerName} <br></br>{rank.teamName}
                                                                            </div></td>
                                                                        <td><img src={`${TeamImageURL}${rank.teamID}${TeamImageextension}`.startsWith('http://') ? profile : `${TeamImageURL}${rank.teamID}${TeamImageextension}`} style={{
                                                                            width: '30px', height: '20px',
                                                                        }} onError={handleImageError} /></td>
                                                                        <td>{rank.Points}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Tab.Pane>

                            <Tab.Pane eventKey="teams" active={activeTab === 'teams'}>

                                <Tab.Container id="inner-tabs" defaultActiveKey="odi">
                                    <Nav variant="tabs" className="mb-3">
                                        <Nav.Item>
                                            <Nav.Link eventKey="odi" onClick={() => setInnerActiveTab4('odi')}>ODI</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="t20" onClick={() => setInnerActiveTab4('t20')}>T20</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="test" onClick={() => setInnerActiveTab4('test')}>TEST</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="odi" active={innerActiveTab4 === 'odi'}>
                                            {/* Content for Batting */}
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Player</th>
                                                                <th>Flags</th>
                                                                <th>Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loading && !error && ranking && ranking.ODI && ranking.ODI.men.team.rank.length > 0 &&
                                                                ranking.ODI.men.team.rank.map((rank, index) => (
                                                                    <tr key={index}>
                                                                        <td>{rank.position}</td>
                                                                        <td >{rank.teamName}</td>
                                                                        <td><img src={`${TeamImageURL}${rank.teamID}${TeamImageextension}`.startsWith('http://') ? profile : `${TeamImageURL}${rank.teamID}${TeamImageextension}`} style={{
                                                                            width: '30px', height: '20px',
                                                                        }} onError={handleImageError} /></td>
                                                                        <td>{rank.Rating}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="t20" active={innerActiveTab4 === 't20'}>
                                            {/* Content for Bowling */}
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Player</th>
                                                                <th>Flags</th>
                                                                <th>Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loading && !error && ranking && ranking.T20 && ranking.T20.men.team.rank.length > 0 &&
                                                                ranking.T20.men.team.rank.map((rank, index) => (
                                                                    <tr key={index}>
                                                                        <td>{rank.position}</td>
                                                                        <td >{rank.teamName}</td>
                                                                        <td><img src={`${TeamImageURL}${rank.teamID}${TeamImageextension}`.startsWith('http://') ? profile : `${TeamImageURL}${rank.teamID}${TeamImageextension}`} style={{
                                                                            width: '30px', height: '20px',
                                                                        }} onError={handleImageError} /></td>
                                                                        <td>{rank.Rating}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="test" active={innerActiveTab4 === 'test'}>
                                            {/* Content for Bowling */}
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Player</th>
                                                                <th>Flags</th>
                                                                <th>Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loading && !error && ranking && ranking.Test && ranking.Test.men.team.rank.length > 0 &&
                                                                ranking.Test.men.team.rank.map((rank, index) => (
                                                                    <tr key={index}>
                                                                        <td>{rank.position}</td>
                                                                        <td >{rank.teamName}</td>
                                                                        <td><img src={`${TeamImageURL}${rank.teamID}${TeamImageextension}`.startsWith('http://') ? profile : `${TeamImageURL}${rank.teamID}${TeamImageextension}`} style={{
                                                                            width: '30px', height: '20px',
                                                                        }} onError={handleImageError} /></td>
                                                                        <td>{rank.Rating}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Tab.Pane>

                        </Tab.Content>

                    </div>
                    <div className='col-md-4 pt-3'>
                        <Ads />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Ranking;