import { useState, useEffect } from 'react';
import '../css/style.css';
import Header from '../components/Header';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Table, Row, Col, Card, Tab, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import profile from '../assets/images/profile.png';
import Ads from '../components/ads';
import { PlayerImageURL } from '../components/global';
import { Helmet } from 'react-helmet';
import Lottie from 'react-lottie';
import run0 from '../assets/jsons/run0.json';
import run1 from '../assets/jsons/run1.json';
import run2 from '../assets/jsons/run2.json';
import run3 from '../assets/jsons/run3.json';
import ball from '../assets/jsons/ball.json';
import drinkbreak from '../assets/jsons/drinkbreak.json';
import finish from '../assets/jsons/finish.json';
import four from '../assets/jsons/four.json';
import freehit from '../assets/jsons/freehit.json';
import newover from '../assets/jsons/newover.json';
import noball from '../assets/jsons/noball.json';
import reason from '../assets/jsons/reason.json';
import six from '../assets/jsons/six.json';
import wicket from '../assets/jsons/wicket.json';
import wide from '../assets/jsons/wide.json';
import loading from '../assets/jsons/loading.json';
import live from '../assets/jsons/live.json';
import lunch from '../assets/jsons/lunch.json';

const animations = {
  run0,
  run1,
  run2,
  run3,
  ball,
  drinkbreak,
  finish,
  four,
  freehit,
  newover,
  noball,
  reason,
  six,
  wicket,
  wide,
  loading,
  live,
  lunch
};


function Liveline() {
  const [searchParams] = useSearchParams();
  const DYMatchId = searchParams.get('MatchId');

  const [activeTab, setActiveTab] = useState('liveScore');
  const [innerActiveTab, setInnerActiveTab] = useState('teamA');
  const [innerBallActiveTab, setBallInnerActiveTab] = useState('ballA');

  const [liveLines, setLiveLine] = useState([]);
  const [players, setPlayers] = useState([]);
  const [playerBalls, setBalls] = useState([]);
  const [error, setError] = useState('');

  const [selectedAnimation, setSelectedAnimation] = useState('loading'); // Default animation
  const containsSubstring = (str, substring) => {
    return str.includes(substring);
  };

  useEffect(() => {
    let isMounted = true; // Flag to prevent state updates if component is unmounted

    const fetchLiveLine = async () => {
      try {
        const liveLineUrl = 'https://api.cricball.in/LiveLine_Match.php';
        const playerListUrl = 'https://api.cricball.in/GetAllPlayers.php';
        const ballListUrl = 'https://api.cricball.in/MatchOdds.php';

        // Fetch all data in parallel
        const [liveLineResponse, playerListResponse, ballListResponse] = await Promise.all([
          axios.post(liveLineUrl, { MatchId: DYMatchId }),
          axios.post(playerListUrl, { MatchId: DYMatchId }),
          axios.post(ballListUrl, { MatchId: DYMatchId }),
        ]);

        if (isMounted) {
          // Ensure Playerslist is defined
          setPlayers(playerListResponse.data?.Playerslist || []);
          setLiveLine(liveLineResponse.data || []);
          setBalls(ballListResponse.data?.Matchst || []);

          // Process the latest data directly, not from the state
          if (liveLineResponse.data.length > 0) {
            const latestData = liveLineResponse.data[liveLineResponse.data.length - 1];
            let newJsonData = latestData.jsondata;
            newJsonData = newJsonData.replace(/[\n\t\r]/g, ' ');
            const parseJson = JSON.parse(newJsonData);
            const score = parseJson?.jsondata?.score;

            // Update selected animation based on score or other conditions
            if (containsSubstring(score, '0')) {
              setSelectedAnimation('run0');
            } else if (score === '1') {
              setSelectedAnimation('run1');
            } else if (score === '2') {
              setSelectedAnimation('run2');
            } else if (score === '3') {
              setSelectedAnimation('run3');
            } else if (containsSubstring(score, 'LUNCH BREAK')) {
              setSelectedAnimation('lunch');
            } else if (containsSubstring(score, '4-4-4')) {
              setSelectedAnimation('four');
            } else if (containsSubstring(score, '6-6-6')) {
              setSelectedAnimation('six');
            } else if (containsSubstring(score, 'Wicket')) {
              setSelectedAnimation('wicket');
            } else if (containsSubstring(score, 'Over')) {
              setSelectedAnimation('newover');
            } else if (containsSubstring(score, 'Wide Ball')) {
              setSelectedAnimation('wide');
            } else if (containsSubstring(score, 'No Ball')) {
              setSelectedAnimation('noball');
            } else if (containsSubstring(score, 'FREE HIT')) {
              setSelectedAnimation('freehit');
            } else if (containsSubstring(score, 'Ball')) {
              setSelectedAnimation('ball');
            } else if (containsSubstring(score, 'Ball In The Air')) {
              setSelectedAnimation('air');
            } else if (containsSubstring(score, 'Won by')) {
              setSelectedAnimation('finish');
            } else {
              setSelectedAnimation('loading');
            }
          }
        }
      } catch (error) {
        if (isMounted) {
          if (error.response) {
            setError(`Error: ${error.response.status} - ${error.response.data}`);
          } else if (error.request) {
            setError('Network Error: No response received from server');
          } else {
            setError(`Error: ${error.message}`);
          }
        }
      }
    };

    // Fetch data initially
    fetchLiveLine();

    // Set up interval to fetch every 2 seconds
    const intervalId = setInterval(fetchLiveLine, 2000);

    // Cleanup on component unmount
    return () => {
      clearInterval(intervalId); // Clear interval
      isMounted = false; // Prevent state updates
    };
  }, [DYMatchId]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animations[selectedAnimation],
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };


  const LoadingSkeleton = () => {
    return (
      <Tab.Pane eventKey="liveScore" active={activeTab === 'liveScore'}>
        <Card className="mb-3">
          <Card.Body>
            <Row>
              <Col xs={5} sm={5} md={5} className="text-center">
                <h5>Team A</h5>
                <h4>0/0</h4>
                <p>0.0</p>
              </Col>
              <Col xs={2} sm={2} md={2} className="text-center">
                <h4>VS</h4>
              </Col>
              <Col xs={5} sm={5} md={5} className="text-center">
                <h5>Team B</h5>
                <h4>0/0</h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <Card.Body className="text-center">
            <Lottie options={defaultOptions} height={100} width={200} />
          </Card.Body>
        </Card>

        {/* Stats */}
        <Row>
          <Col md={3}>
            <Card className="mb-3">
              <Card.Body>
                <p>Cr. Run rate</p>
                <h5>0.00</h5>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="mb-3">
              <Card.Body>
                <p>Re. Run rate</p>
                <h5>00</h5>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="mb-3">
              <Card.Body>
                <p>Need Run</p>
                <h5>00</h5>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="mb-3">
              <Card.Body>
                <p>Balls</p>
                <h5>00</h5>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Card className="mb-3">
          <Card.Body className="text-center">
            <p>Last 6 Balls</p>
            <div>
              <span className="circle bg-light">. </span>
              <span className="circle bg-light">. </span>
              <span className="circle bg-light">. </span>
              <span className="circle bg-light">. </span>
              <span className="circle bg-light">. </span>
              <span className="circle bg-light">. </span>
            </div>
          </Card.Body>
        </Card>

        {/* Batsman & Bowler */}
        <Card className="mb-3">
          <Card.Body>
            <Row>
              <Col>
                <p>Batsman</p>
                <p>Player1</p>
                <p>Player2</p>
                <p>Bowler</p>
              </Col>
              <Col>
                <p>R</p>
                <p>00</p>
                <p>00</p>
                <p>00</p>
              </Col>
              <Col>
                <p>B</p>
                <p>00</p>
                <p>00</p>
              </Col>
              <Col>
                <p>4s</p>
                <p>00</p>
                <p>00</p>
              </Col>
              <Col>
                <p>6s</p>
                <p>00</p>
                <p>00</p>
              </Col>
              <Col>
                <p>SR</p>
                <p>0.00</p>
                <p>0.00</p>
              </Col>

            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <Card.Body>
            <h4>Match Summary</h4>
            <p>Match Summary</p>
          </Card.Body>
        </Card>

      </Tab.Pane>
    );
  };



  return (
    <>
      <Helmet>
        <title>Cricball - Liveline Matches</title>
        <meta name="description" content="Get Live Cricket Scores, Ball by Ball Commentary, Scorecard Updates, Match Facts &amp; related News of all the International &amp; Domestic Cricket Matches across the globe." />
        <meta name="keywords" content="Cricball, 1 Ball Ago, Live Score, Live Cricket Matches" />
      </Helmet>
      <Header />
      <div className='container'>
        <div className='row'>
          <div className='col-md-8 pt-3'>
            <Nav variant="tabs" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)}>
              <Nav.Item>
                <Nav.Link eventKey="liveScore">Live Score</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="playerList">Player List</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="ballByBall">Ball by Ball</Nav.Link>
              </Nav.Item>
            </Nav>

            {/* Tab Content */}
            <Tab.Content className="mt-4">
              {/* Live Score Tab */}

              {liveLines.length === 0 || liveLines.every(line => !line.jsondata || !line.jsonruns) ? (
                <LoadingSkeleton />
              ) : (liveLines.map((livedata, index) => {

                let newJsonData = livedata.jsondata;
                newJsonData = newJsonData.replace(/[\n\t\r]/g, ' ');
                const parseJson = JSON.parse(newJsonData);

                let newJsonrunsData = livedata.jsonruns;
                newJsonrunsData = newJsonrunsData.replace(/[\n\t\r]/g, ' ');
                const finalJsonrunsData = JSON.parse(newJsonrunsData);

                //Current Run Rate
                const runratedata = parseJson.jsondata.title;
                const currentrunrate = runratedata.match(/C\.RR:\s*([\d.]+)/)?.[1] || null;

                //Last 6 Balls
                const last6balldata = parseJson.jsondata.Last6Balls;
                const last6balls = last6balldata.split('-');

                //Batsman Name
                const batsmandata = parseJson.jsondata.batsman;
                const allbatsmandata = batsmandata.split('|');

                //Run data 
                const rundata = parseJson.jsondata.oversB;
                const rundatasplit = rundata.split('|');
                const rundataA = rundatasplit[0];
                const rundataAsplit = rundataA.split(',');
                const runA = rundataAsplit[0];
                const runB = rundataAsplit[1];

                //Ball Data
                const balldataB = rundatasplit[1];
                const balldataBsplit = balldataB.split(',');
                const ballA = balldataBsplit[0];
                const ballB = balldataBsplit[1];


                //Strike Rate Data
                const playAstrikerate = (runB / ballB) * 100;
                const playBstrikerate = (runA / ballA) * 100;

                const score = parseJson.jsondata.score;

                return (
                  <Tab.Pane key={index} eventKey="liveScore" active={activeTab === 'liveScore'}>
                    <Card className="mb-3">
                      <Card.Body>
                        <Row>
                          <Col xs={5} sm={5} md={5} className="text-center">
                            <h5>{parseJson.jsondata.teamA}</h5>
                            <h4>{parseJson.jsondata.wicketA}</h4>
                            <p>{parseJson.jsondata.oversA}</p>
                          </Col>
                          <Col xs={2} sm={2} md={2} className="text-center">
                            <h4>VS</h4>
                          </Col>
                          <Col xs={5} sm={5} md={5} className="text-center">
                            <h5>{parseJson.jsondata.teamB}</h5>
                            <h4>{parseJson.jsondata.wicketB}</h4>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="mb-3">
                      <Card.Body className="text-center">
                        {/* <p>{parseJson.jsondata.score}</p>   */}
                        <Lottie options={defaultOptions} height={100} width={200} />
                      </Card.Body>
                    </Card>

                    {/* Stats */}
                    <Row>
                      <Col md={3}>
                        <Card className="mb-3">
                          <Card.Body>
                            <p>Cr. Run rate</p>
                            <h5>{currentrunrate}</h5>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={3}>
                        <Card className="mb-3">
                          <Card.Body>
                            <p>Re. Run rate</p>
                            <h5>00</h5>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={3}>
                        <Card className="mb-3">
                          <Card.Body>
                            <p>Need Run</p>
                            <h5>00</h5>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={3}>
                        <Card className="mb-3">
                          <Card.Body>
                            <p>Balls</p>
                            <h5>00</h5>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <Card className="mb-3">
                      <Card.Body className="text-center">
                        <p>Last 6 Balls</p>
                        <div>
                          {last6balls.map((value, index) => (
                            <span key={index} className="circle bg-light">{value} </span>
                          ))}

                        </div>
                      </Card.Body>
                    </Card>

                    {/* Batsman & Bowler */}
                    <Card className="mb-3">
                      <Card.Body>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Batsman</th>
                              <th>R</th>
                              <th>B</th>
                              <th>4s</th>
                              <th>6s</th>
                              <th>SR</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* Row for first Batsman */}
                            <tr>
                              <td>{allbatsmandata[0] ? allbatsmandata[0] : 'Player1'}</td>
                              <td>{runB}</td>
                              <td>{ballB}</td>
                              <td>{parseJson.jsondata.s4}</td>
                              <td>{parseJson.jsondata.s6}</td>
                              <td>{playAstrikerate.toFixed(2) ? playAstrikerate.toFixed(2) : '0.00'}</td>
                            </tr>

                            {/* Row for second Batsman */}
                            <tr>
                              <td>{allbatsmandata[1] ? allbatsmandata[1] : 'Player2'}</td>
                              <td>{runA}</td>
                              <td>{ballA}</td>
                              <td>{parseJson.jsondata.ns4}</td>
                              <td>{parseJson.jsondata.ns6}</td>
                              <td>{playBstrikerate.toFixed(2) ? playBstrikerate.toFixed(2) : '0.00'}</td>
                            </tr>

                            {/* Bowler Row */}
                            <tr>
                              <td colSpan="6">
                                <strong>Bowler: </strong>{parseJson.jsondata.bowler}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>


                    <Card className="mb-3">
                      <Card.Body>
                        <h4>Match Summary</h4>
                        <p>{finalJsonrunsData.jsonruns.summary}</p>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                )
              }))}

              {/* Player List Tab */}
              <Tab.Pane eventKey="playerList" active={activeTab === 'playerList'}>
                <h4>Player List</h4>
                {/* Inner Tabs for Player List */}
                <Tab.Container id="inner-tabs" defaultActiveKey="teamA">
                  <Nav variant="tabs" className="mb-3">
                    <Nav.Item>
                      <Nav.Link eventKey="teamA" onClick={() => setInnerActiveTab('teamA')}>teamA</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="teamB" onClick={() => setInnerActiveTab('teamB')}>teamB</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="teamA" active={innerActiveTab === 'teamA'}>
                      {/* Content for Batting */}
                      <Card className="mb-3">
                        <Card.Body>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th></th>
                                <th></th>
                                <th>R</th>
                                <th>B</th>
                                <th>4s</th>
                                <th>6s</th>
                                <th>SR</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Row for first Batsman */}
                              {players.slice(0, 11).map((playersdata, index) => {
                                const strikerateplayers = (playersdata.Runs / playersdata.Balls) * 100;
                                const staticplayerimage = PlayerImageURL;
                                const playerprofile = staticplayerimage + playersdata.PlayerImage;
                                return (
                                  <tr key={index}>
                                    <td><img src={playerprofile.startsWith('http://') ? profile : playerprofile} width={40} height={40} style={{ borderRadius: '100px' }} onError={(e) => e.target.src = profile} /></td>
                                    <td><p className='mb-0'>{playersdata.PlayerName}</p><span>{playersdata.outby}</span></td>
                                    <td>{playersdata.Runs}</td>
                                    <td>{playersdata.Balls}</td>
                                    <td>{playersdata.four}</td>
                                    <td>{playersdata.six}</td>
                                    <td>{strikerateplayers.toFixed(2)}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey="teamB" active={innerActiveTab === 'teamB'}>
                      {/* Content for Bowling */}
                      <Card className="mb-3">
                        <Card.Body>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th></th>
                                <th></th>
                                <th>R</th>
                                <th>B</th>
                                <th>4s</th>
                                <th>6s</th>
                                <th>SR</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Row for first Batsman */}
                              {players.slice(11, 22).map((playersdata, index) => {
                                const strikerateplayers = (playersdata.Runs / playersdata.Balls) * 100;
                                const staticplayerimage = PlayerImageURL;
                                const playerprofile = staticplayerimage + playersdata.PlayerImage;
                                return (
                                  <tr key={index}>
                                    <td><img src={playerprofile.startsWith('http://') ? profile : playerprofile} width={40} height={40} style={{ borderRadius: '100px' }} onError={(e) => e.target.src = profile} /></td>
                                    <td><p className='mb-0'>{playersdata.PlayerName}</p><span>{playersdata.outby}</span></td>
                                    <td>{playersdata.Runs}</td>
                                    <td>{playersdata.Balls}</td>
                                    <td>{playersdata.four}</td>
                                    <td>{playersdata.six}</td>
                                    <td>{strikerateplayers.toFixed(2)}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Tab.Pane>

              {/* Ball by Ball Tab */}
              <Tab.Pane eventKey="ballByBall" active={activeTab === 'ballByBall'}>
                <h4>Ball by Ball Score</h4>
                {/* Add your content here */}
                <Tab.Container id="inner-tabs" defaultActiveKey="ballA">
                  <Nav variant="tabs" className="mb-3">
                    <Nav.Item>
                      <Nav.Link eventKey="ballA" onClick={() => setBallInnerActiveTab('ballA')}>Team A</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="ballB" onClick={() => setBallInnerActiveTab('ballB')}>Team B</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="ballA" active={innerBallActiveTab === 'ballA'}>
                      {/* Content for Batting */}
                      <Card className="mb-3">
                        <Card.Body>
                          <Table striped bordered hover>
                            <tbody>
                              {/* Row for first Batsman */}
                              {playerBalls.slice().reverse().map((playerBallsdata, index) => {
                                if (playerBallsdata.isfirstinning == '1') {
                                  return (
                                    <tr key={index}>
                                      <td>{playerBallsdata.Score}</td>
                                      <td>{playerBallsdata.overs}</td>
                                      <td>Time: {playerBallsdata.subdate.split(' ')[1].slice(0, 5)}</td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey="teamB" active={innerBallActiveTab === 'ballB'}>
                      {/* Content for Bowling */}
                      <Card className="mb-3">
                        <Card.Body>
                          <Table striped bordered hover>
                            <tbody>
                              {/* Row for first Batsman */}
                              {playerBalls.slice().reverse().map((playerBallsdata, index) => {
                                if (playerBallsdata.isfirstinning == '2') {
                                  return (
                                    <tr key={index}>
                                      <td>{playerBallsdata.Score}</td>
                                      <td>{playerBallsdata.overs}</td>
                                      <td>Time: {playerBallsdata.subdate.split(' ')[1].slice(0, 5)}</td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Tab.Pane>
            </Tab.Content>

          </div>
          <div className='col-md-4 pt-3'>
            <Ads />
          </div>
        </div>
      </div>
    </>
  );



}

export default Liveline;
