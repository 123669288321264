import React from 'react';
import Header from '../components/Header';
import Dashboardslider from '../components/dashboard/Dashboardslider';
import Serieslist from '../components/dashboard/Serieslist';
import { Helmet } from 'react-helmet';


function Dashboard() {

    return (
        <>
            <Helmet>
                <title>Cricball - Live Match Prediction</title>
                <meta name="description" content="Get Live Cricket Scores, Ball by Ball Commentary, Scorecard Updates, Match Facts &amp; related News of all the International &amp; Domestic Cricket Matches across the globe." />
                <meta name="keywords" content="Cricball, 1 Ball Ago, Live Score, Live Cricket Matches" />
            </Helmet>

            <Header />
            <Dashboardslider />
            <Serieslist />
        </>
    );
}

export default Dashboard;
