import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import '../css/style.css';
import axios from 'axios';
import { Col, Container, Row, Card, CardBody } from 'react-bootstrap';
import profile from '../assets/images/profile.png'; // Fallback image
import Ads from '../components/ads';
import { useSearchParams } from 'react-router-dom';
import { TeamImageURL, TeamImageextension } from '../components/global';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function SeriesDetails() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const DYSeriesID = searchParams.get('SeriesID');

    const [seriesMatches, setSeriesMatches] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSeriesMatches = async () => {
            const apiUrl = 'https://apiv2.cricket.com/cricket/';
            const query = {
                query: `query {
                    matcheslist(seriesID: "${DYSeriesID}") {
                        matchName
                        homeTeamShortName
                        awayTeamShortName
                        venue
                        matchID
                        startDate
                        matchResult
                        matchenddate
                        matchScore {
                            teamShortName
                            teamID
                            teamFullName
                        }
                    }
                }`
            };
            setLoading(true);

            try {
                const response = await axios.post(apiUrl, query, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setSeriesMatches(response.data.data.matcheslist || []);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
        fetchSeriesMatches();
    }, [DYSeriesID]);

    const getImageUrl = (teamID) => {
        return `${TeamImageURL}${teamID}${TeamImageextension}`;
    };

    const handleImageError = (e) => {
        e.target.src = profile; // Fallback image
    };

    return (
        <>
        <Helmet>
                <title>Cricball - Series Match Details</title>
                <meta name="description" content="Get Live Cricket Scores, Ball by Ball Commentary, Scorecard Updates, Match Facts &amp; related News of all the International &amp; Domestic Cricket Matches across the globe." />
                <meta name="keywords" content="Cricball, 1 Ball Ago, Live Score, Live Cricket Matches" />
            </Helmet>
            <Header />
            <Container>
                <Row>
                    <Col md={8} className='pt-3 pb-3'>
                        {loading && <p>Loading...</p>}
                        {!loading && !error && seriesMatches.length === 0 && (
                            <Card className='text-center'>
                                <CardBody>
                                    <p>No Data Found</p>
                                </CardBody>
                            </Card>
                        )}
                        {!loading && !error && seriesMatches.length > 0 && seriesMatches.map((match, index) => {
                            const teamAImage = getImageUrl(match.matchScore[0].teamID);
                            const teamBImage = getImageUrl(match.matchScore[1].teamID);

                            const timestamp = Number(match.startDate);
                            const date = new Date(timestamp);

                            // Format the date as 'd M Y'
                            const matchstartDate = date.toLocaleDateString('en-GB', {
                                day: '2-digit',    // 'd' -> day
                                month: 'short',    // 'M' -> month (short form like "Sep")
                                year: 'numeric'    // 'Y' -> year
                            });

                            const formattedTime = date.toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true, // Enables AM/PM format
                            });

                            return (
                                <Card className='mt-3' key={index} style={{ width: '100%', borderRadius: '15px', padding: '15px', border: 'none' }} onClick={() => navigate(`/SeriesScore?matchID=${match.matchID}`)}>
                                    {/* League and Date Row */}
                                    <Row className="mb-2">
                                        <Col>
                                            <h6 style={{ margin: 0 }}>{match.matchName}</h6>
                                        </Col>
                                        <Col xs="auto">
                                            Start At:  <span style={{ color: '#0d6efd', fontWeight: 'bold' }}>{formattedTime}</span>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>
                                            <h6 style={{ margin: 0 }}>{match.venue} - {matchstartDate}</h6>
                                        </Col>
                                    </Row>

                                    {/* Teams Row */}
                                    <Row className="align-items-center mb-3">
                                        <Col xs={2} className="d-flex justify-content-center">
                                            <img
                                                src={teamAImage}
                                                alt={match.homeTeamShortName}
                                                onError={handleImageError} // Fallback to profile image on error
                                                className="rounded-circle"
                                                style={{ width: '40px', height: '40px' }}
                                            />
                                        </Col>
                                        <Col>
                                            <strong>{match.homeTeamShortName}</strong>
                                        </Col>
                                    </Row>

                                    <Row className="align-items-center mb-2">
                                        <Col xs={2} className="d-flex justify-content-center">
                                            <img
                                                src={teamBImage}
                                                alt={match.awayTeamShortName}
                                                onError={handleImageError} // Fallback to profile image on error
                                                className="rounded-circle"
                                                style={{ width: '40px', height: '40px' }}
                                            />
                                        </Col>
                                        <Col>
                                            <strong>{match.awayTeamShortName}</strong>
                                        </Col>
                                    </Row>

                                    <Row className=" text-center">
                                        <Col>
                                            <p className="text-muted mb-0">{loading ? 'Match Time at 00:00 AM/PM' : match.matchResult}</p>
                                        </Col>
                                    </Row>

                                </Card>
                            );
                        })}
                    </Col>

                    <Col md={4} className='pt-3'>
                        <Ads />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SeriesDetails;
