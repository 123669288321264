import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import '../css/style.css';
import axios from 'axios';
import { Col, Container, Row, Card } from 'react-bootstrap';
import profile from '../assets/images/profile.png';
import { useNavigate } from 'react-router-dom';
import Ads from '../components/ads';
import { Helmet } from 'react-helmet';

function Livescore() {


    const navigate = useNavigate();

    const [liveMatches, setLiveMatches] = useState(Array(3).fill({ Title: '', TeamA: '', team1Score: '', TeamB: '', team2Score: '', Matchtime: '' }));
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLiveMatches = async () => {
            setLoading(true);
            try {
                const targetUrl = 'https://api.cricball.in/liveline.php';
                const response = await axios.get(targetUrl);
                setLiveMatches(response.data || []);
            } catch (error) {
                if (error.response) {
                    setError(`Error: ${error.response.status} - ${error.response.data}`);
                } else if (error.request) {
                    setError('Network Error: No response received from server');
                } else {
                    setError(`Error: ${error.message}`);
                }
            } finally {
                setLoading(false);
            }
        };
        fetchLiveMatches();
    }, []);


    const formatDate = (dateStr) => {
        if (!dateStr) return null; // Safeguard for empty or undefined date
        const parts = dateStr.split('-');
        if (parts.length !== 3) return null; // Ensure the date format is valid
        const [day, month, year] = parts;
        return new Date(`${month}/${day}/${year}`);
    };

    const handleImageError = (e) => {
        e.target.src = profile; // Fallback image
    };

    return (
        <>
        <Helmet>
        <title>Cricball - Live Score</title>
        <meta name="description" content="Get Live Cricket Scores, Ball by Ball Commentary, Scorecard Updates, Match Facts &amp; related News of all the International &amp; Domestic Cricket Matches across the globe." />
        <meta name="keywords" content="Cricball, 1 Ball Ago, Live Score, Live Cricket Matches" />
      </Helmet>
            <Header />
            <Container>
                <Row>
                    <Col md={8} className='pt-3 pb-3'>
                        {liveMatches.map((match, index) => {
                            let teamAImage = match.ImgeURL + match.TeamAImage;
                            let teamBImage = match.ImgeURL + match.TeamBImage;
                            const matchDate = formatDate(match.MatchDate);
                            const today = new Date();
                            const isToday = matchDate && today.toDateString() === matchDate.toDateString();
                            const isResult = /won by/i.test(match.Result);

                            if (match.jsondata !== undefined && match.jsondata !== "") {
                                let newJsonData = match.jsondata;
                                newJsonData = newJsonData.replace(/[\n\t\r]/g, ' ');
                                const parseJson = JSON.parse(newJsonData);
                                const isLive = parseJson.jsondata.batsman !== "*|";

                                const matchStatus = isResult
                                    ? 'Result'
                                    : isLive
                                        ? 'Live'
                                        : isToday
                                            ? 'Today'
                                            : 'Upcoming';
                                return (
                                    <Card className='mt-3' key={index} style={{ width: '100%', borderRadius: '15px', padding: '15px', border: 'none' }} onClick={() => navigate(`/liveline?MatchId=${match.MatchId}`)}>
                                        {/* League and Date Row */}
                                        <Row className="mb-2">
                                            <Col>
                                                <h6 style={{ margin: 0 }}>{loading ? 'Match Name' : match.Title}</h6>
                                            </Col>
                                            <Col xs="auto">
                                                <span style={{ color: '#0d6efd', fontWeight: 'bold' }}>{loading ? 'Status' : matchStatus}</span>
                                            </Col>

                                        </Row>

                                        {/* Teams Row */}
                                        <Row className="align-items-center mb-3">
                                            <Col xs={2} className="d-flex justify-content-center">
                                                <img
                                                    src={loading ? profile : teamAImage.startsWith('http://') ? profile : teamAImage}
                                                    alt={teamAImage}
                                                    onError={handleImageError}
                                                    className="rounded-circle"
                                                    style={{ width: '40px', height: '40px' }}
                                                />
                                            </Col>
                                            <Col>
                                                <strong>{loading ? 'Team A' : match.TeamA}</strong>
                                            </Col>
                                            <Col style={{ textAlign: 'right' }}>
                                                <strong>{loading ? '(0/0)' : `(${parseJson.jsondata.wicketA})`}</strong>
                                            </Col>
                                        </Row>

                                        <Row className="align-items-center mb-2">
                                            <Col xs={2} className="d-flex justify-content-center">
                                                <img
                                                    src={loading ? profile : teamBImage.startsWith('http://') ? profile : teamBImage}
                                                    alt={teamBImage}
                                                    onError={handleImageError}
                                                    className="rounded-circle"
                                                    style={{ width: '40px', height: '40px' }}
                                                />
                                            </Col>
                                            <Col>
                                                <strong>{loading ? 'Team B' : match.TeamB}</strong>
                                            </Col>
                                            <Col style={{ textAlign: 'right' }}>
                                                <strong>{loading ? '(0/0)' : `(${parseJson.jsondata.wicketB})`}</strong>
                                            </Col>
                                        </Row>

                                        {/* Date and Time */}
                                        <Row className=" text-center">
                                            <Col>
                                                <p className="text-muted mb-0">{loading ? 'Match Time at 00:00 AM/PM' : isResult ? match.Result : match.Matchtime}</p>
                                            </Col>
                                        </Row>
                                    </Card>
                                );
                            } else {
                                const matchStatus = isResult
                                    ? 'Result'
                                    : isToday
                                        ? 'Today'
                                        : 'Upcoming';
                                return (
                                    <Card key={index} className='mt-3' style={{ width: '100%', borderRadius: '15px', padding: '15px', border: 'none' }} onClick={() => navigate(`/liveline?MatchId=${match.MatchId}`)}>
                                        {/* League and Date Row */}
                                        <Row className="mb-2">
                                            <Col>
                                                <h6 style={{ margin: 0 }}>{loading ? 'Match Name' : match.Title}</h6>
                                            </Col>
                                            <Col xs="auto">
                                                <span style={{ color: '#0d6efd', fontWeight: 'bold' }}>{loading ? 'Status' : matchStatus}</span>
                                            </Col>
                                        </Row>

                                        {/* Teams Row */}
                                        <Row className="align-items-center mb-3">
                                            <Col xs={2} className="d-flex justify-content-center">
                                                <img
                                                    src={loading ? profile : teamAImage.startsWith('http://') ? profile : teamAImage}
                                                    alt={loading ? profile : teamAImage}
                                                    onError={handleImageError}
                                                    className="rounded-circle"
                                                    style={{ width: '40px', height: '40px' }}
                                                />
                                            </Col>
                                            <Col>
                                                <strong>{loading ? 'Team A' : match.TeamA}</strong>
                                            </Col>
                                            <Col style={{ textAlign: 'right' }}>
                                                <strong>(0/0)</strong>
                                            </Col>
                                        </Row>

                                        <Row className="align-items-center mb-2">
                                            <Col xs={2} className="d-flex justify-content-center">
                                                <img
                                                    src={loading ? profile : teamBImage.startsWith('http://') ? profile : teamBImage}
                                                    alt={loading ? profile : teamBImage}
                                                    onError={handleImageError}
                                                    className="rounded-circle"
                                                    style={{ width: '40px', height: '40px' }}
                                                />
                                            </Col>
                                            <Col>
                                                <strong>{loading ? 'Team B' : match.TeamB}</strong>
                                            </Col>
                                            <Col style={{ textAlign: 'right' }}>
                                                <strong>(0/0)</strong>
                                            </Col>
                                        </Row>

                                        {/* Date and Time */}
                                        <Row className=" text-center">
                                            <Col>
                                                <p className="text-muted mb-0">{loading ? 'Match Time at 00:00 AM/PM' : isResult ? match.Result : match.Matchtime}</p>
                                            </Col>
                                        </Row>
                                    </Card>
                                );
                            }
                        })}
                    </Col>

                    <Col md={4} className='pt-3'>
                        <Ads />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Livescore;